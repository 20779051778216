// App.js
import { Analytics } from "@vercel/analytics/react"
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header.js';
import Footer from './Components/Footer.js';
import Contact from './Pages/Contact.js';
import Home from './Pages/Home.js'; 
import Residential from './Pages/Residential.js'; 
import Commercial from './Pages/Commercial.js'; 


function App() {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/contact" element={<Contact />} /> 
          <Route path="/residential" element={<Residential />} /> 
          <Route path="/commercial" element={<Commercial />} /> 
           {/* <Route path="/about" element={<About />} /> About page route */}
        </Routes>
        <Footer />
        <Analytics/>
      </div>
    </Router>
  );
}

export default App;
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import Image1 from "../WebImg/ResImg/Interior1.jpg";
import Image2 from "../WebImg/ResImg/Res_1.png";
import Image3 from "../WebImg/ResImg/Net_1.png";
import "../Styles/Res_Style.css";
import "../index.css";

function Residential() {
  useEffect(() => {window.scrollTo(0, 0);}, []);

  return (
    <>
      {/* Header */}
      <header className="headerR">
        <div className="headerTitleR">
          <div>
            <h1 className ="headingStyleR">Residential Security Solutions</h1>
          </div>
        </div>
          <div className="IMGcontainerR">
            <div className="IMGcardHeaderR" style={{backgroundImage: `url(${Image1})`, height: 300, width: '100%', marginLeft: 0}}> </div>
          </div>
      </header>

      {/* Introduction */}
      <section>
        <div className="infoBoxTR">
          <p>
            Throughout the Valley, we offer a comprehensive range of home surveillance solutions, 
            including high-definition cameras, motion detection sensors, and smart integration features, 
            tailored to meet your specific security needs. Our advanced electronic systems ensure that your home is protected with the latest in security technology.
          </p>
        </div>
      </section>

      {/* Surveillance */}
      <section>
        <div className="TotalContR">
          <div className="container1R" style={{ marginBottom: '20px', justifyContent: 'center' }}>
            {/* LazyLoad wrapper for Image2 */}
            <LazyLoad>
              <div className="IMGholderR" style={{ backgroundImage: `url(${Image2})` }} alt="Residential home surveillance system"></div>
            </LazyLoad>
            <div className="infoBoxR infoBoxCamR">
              <h2>HOME SURVEILLANCE</h2>
              <p>
                Our home surveillance services offer peace of mind by providing round-the-clock 
                monitoring of your property, ensuring the safety and security of your home. Our CCTV surveillance systems are designed to deliver high-quality video and reliable performance.
              </p>                 
              <ul>
                <li>24/7 FULL COLOR</li>
                <li>5MP & 8MP FIXED WIDE ANGLE</li>
                <li>5MP & 8MP VARIFOCAL</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Networking */}
      <section>
        <div className="TotalContR">
          <div className="container1R" style={{ marginBottom: '20px', justifyContent: 'center', paddingBottom: '50px' }}>
            <div className="infoBoxR infoBoxNetR">
              <h2>NETWORKING FOR HOMES</h2>
              <p>
                Our home networking service enhances connectivity within your household, 
                enabling seamless communication and sharing of resources among devices. Our network security solutions are designed to protect your digital assets and ensure secure connectivity.
                <br/><br/>
                With our expertise in home networking, 
                we create custom solutions that prioritize security, 
                efficiency, and scalability.
              </p>
            </div>
            {/* LazyLoad wrapper for Image3 */}
            <LazyLoad>
              <div className="IMGholderR" style={{ backgroundImage: `url(${Image3})` }} alt="Home networking setup"></div>
            </LazyLoad>
          </div>
        </div>
      </section>

      {/* Other Services */}
      <section>
        <div className="bottomContainterR" style={{ marginTop: 50, marginBottom: 50 }}>
          <div className="infoBoxR">
            <h2>Other Services</h2>
            <p>
              In addition to surveillance, we also specialize in home access control and alarm systems. Our access control solutions provide advanced security measures to manage and monitor entry points, ensuring only authorized individuals can access your home.
            </p>
          </div>
        </div>
      </section>

      {/* Contact Link */}
      <footer>
        <Link className="CButtonR" to="../Contact" style={{ top: 50 }}>
          Ready to Secure your Home?
          <br/> 
        </Link>
      </footer>
    </>
  );
}

export default Residential;

import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Image1 from '../WebImg/CITY.jpg';
import Image3 from "../WebImg/CloseC.jpg";
import Image4 from "../WebImg/IMG_3659.jpg";
import Image5 from "../WebImg/WhiteC.jpg";
import LazyLoad from 'react-lazy-load';
import { TbDeviceCctv } from "react-icons/tb";
import { RiAlarmWarningLine } from "react-icons/ri";
import { MdOndemandVideo } from "react-icons/md";
import { IoIosGitNetwork } from "react-icons/io";
import Slideshow from "../Components/SlideShow";

import "../Styles/SlideShowStyle.css";
import '../Styles/Home_style.css';
import "../index.css";

function preloadImage(src) {
  const img = new Image();
  img.src = src;
}

function Home() {

  useEffect(() => {
    // Preload critical images
    preloadImage(Image1);
    preloadImage(Image3);
    preloadImage(Image4);
    preloadImage(Image5);
  }, []);

  return (
    <div>
      {/* Header */}
      <header className="HeaderContainerH">
        <div className="HeaderTitleH">
          <div className="headingStyleH">
            <h1>SECURE YOUR WORLD WITH ADVANCED SECURITY SOLUTIONS</h1>
          </div>
        </div>
        <div className="IMGcontainerH">
          <div className="IMGcardH" style={{backgroundImage: `url(${Image1})`, height: 300, width: '100%', marginLeft: 0}}></div>
        </div>
      </header>

      {/* Promo */}
      <section className="PromoH">
        <div className="TitlePH">
          <div className="PromoContH" style={{marginTop: 4, fontSize: '1.4rem', textIndent: 25, fontWeight: 'bold'}}>PROMOTION:</div>
        </div>

          <div className="Wtext">
            <p>FREE panel with monitoring agreement!</p>
            <div className="lineBreakH"></div>
            <p >24/7 professional monitoring starting at $34.99!</p>
          </div>
          <div className="Mtext">
            <p>
              FREE panel with monitoring agreement!
            <br/>
              24/7 professional monitoring starting at $34.99!
            </p>
          </div>

      </section>

      {/* Main Body */}
      <main className="MainBodyH">
        {/* TextBody */}
        <article className="TextBodyH">
          <h2>Discover Comprehensive Security Solutions for Your Home and Business</h2>
          <p style={{fontSize: '1.2rem'}}>
            For <strong>home surveillance</strong>, we offer a wide range of security services tailored to meet the unique needs for <strong> residential homes </strong> across the Phoenix Valley. Our commitment to safety and peace of mind extends throughout the region, ensuring that your property remains protected no matter where you are.
            Schedule your free quote today!
            <br />
            <br />
            For businesses, we provide <strong>security solutions</strong> including cutting-edge <strong>surveillance cameras for businesses</strong>, advanced alarm systems, and <strong>business level camera installation</strong> services. Our expert technicians will assess your business premises and design a <strong>customized security solution </strong> to meet your specific requirements, ensuring the safety and security of your staff, customers, and assets. 
          </p>
        </article>

        {/* IconBody */}
        <section className="IconBodyH">
          <div className="IconCardH">
            <div className="iconTextV">
              <TbDeviceCctv size={110}/>
              <span style={{textAlign: 'center'}}> CCTV Surveillance</span>
            </div>
            <div className="iconTextV">
              <p>
              Our <strong>CCTV surveillance</strong> systems provide 24/7 monitoring to ensure the safety of your property.
              </p>
            </div>
          </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <IoIosGitNetwork size={110}/>
          <span style={{textAlign: 'center'}}>Networking Security Solutions</span>
        </div>
        <div className="iconTextV">
          <p>
            Our <strong>network security solutions</strong> enable seamless communication and resource sharing among devices.
          </p>
        </div>

        </div>
      </section>

      <section className="IconBodyH">
        <div className="IconCardH">
          <div className="iconTextV">
            <MdOndemandVideo size={110}/>
            <span style={{textAlign: 'center'}}>Video Solutions</span>
          </div>
          <div className="iconTextV">
            <p>
                Our <strong>video solutions</strong> monitor your house or business, ensuring safety and a peace of mind.
              </p>
          </div>
        </div>

      <div className="IconCardH">
        <div className="iconTextV">
          <RiAlarmWarningLine size={110}/>
          <span style={{textAlign: 'center'}}>Alarm Systems</span>
        </div>
        <div className="iconTextV">
          <p>
          with our <strong>alarm systems</strong> we keep you protected with swift responses to security breaches.
          </p>
        </div>
      </div>
    </section>

     

        {/* IMG Body */}
        <section className="IMGbodyH">
          <aside className="IMGrow" style={{marginRight: '15px'}}>
            <div style={{marginRight: '15px'}}>
              <LazyLoad>
                <div className="IMGcardVH0" style={{backgroundImage: `url(${Image5})`}} alt="White Security Camera"></div>
              </LazyLoad>
              <LazyLoad>
                <div className="IMGcardVH1" style={{backgroundImage: `url(${Image3})`}}alt="Close up of Camera Installation"></div>
              </LazyLoad>
            </div>
      
              <div className="IMGVertH">
                <LazyLoad>
                  <div className="IMGcardVH0" style={{backgroundImage: `url(${Image4})`}}alt="Surveillance Camera Setup In Arizona"></div>
                </LazyLoad>
              </div>
        
          </aside>

          <article className="VertBox">
            <div className="infoBoxH">
              <h2>About Us</h2>
              <p style={{fontSize: '1.2rem'}}>
                GL Electronics is a locally owned and operated low voltage specialist servicing the major Phoenix metropolitan area since 2017. Our knowledgeable team members are trained to offer custom tailored solutions to fit every client's needs. We specialize in intuitive and advanced security electronics technology at competitive prices.
              </p>
            </div>

            <Slideshow />
            
          </article>
        </section>

        <nav>
          <Link to="../Contact" className="CButtonH">Get Started</Link>
        </nav>
      </main>
    </div>
  )
}

export default Home;
//MenuBar.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { FaPhoneAlt } from "react-icons/fa";
import '../Styles/HeaderStyle.css';

function MenuBar({ closeMenu, }) { // Pass closeMenu function as a prop
    
    const phoneNumber = '(480)757-5148'; // Replace with your actual phone number

    const page = {
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        marginLeft: 'auto',
        textDecoration: 'none',
        color: 'black',
        fontSize: '1.2rem',
        backgroundColor: 'white',
        borderBottom: '1px solid darkgrey',
        padding: 10,
    }

    return (
        <nav className="menuBox"> 
            <div>
                <Link to="../Residential" style={page} onClick={closeMenu}> {/* Add onClick event to close the menu */}
                    Residential
                </Link>
            </div>
            <div>
                <Link to="../Commercial" style={page} onClick={closeMenu}> {/* Add onClick event to close the menu */}
                    Commercial
                </Link>
            </div>
            <div>
                <Link to="../Contact" style={page} onClick={closeMenu}> {/* Add onClick event to close the menu */}
                    Contact
                </Link>
            </div>
            <div>
                <a href={`tel:${phoneNumber}`} style={{...page,...{borderBottom: '5px solid darkred'}}}>
                    <FaPhoneAlt size={20} style={{ marginRight: 15, marginTop: 5, }} />
                    {phoneNumber}
                </a>
            </div>
        </nav>
    )
}

export default MenuBar;
// Footer.js

import React from "react";
import { FaInstagram } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { FaYelp } from "react-icons/fa6";
import "../Styles/FooterStyle.css";

function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="infoBox1">
          <h2 style={{ fontSize: '1.4rem' }}>
            CONTACT US
          </h2>
          <span>geoff@glelectronics.net</span>
          <span>(480)757-5148</span>
          <span>Hours of Operation:</span>
          <span>Mon-Fri: 8AM - 8PM</span>
          <span>Sat: 10AM - 6PM</span>
          <span>Sun: CLOSED</span>
        </div>

        <div className="infoBox3">
          <ul>
              <h2 style={{fontSize: '1.4rem'}}> LICENSED / BONDED / INSURED</h2>
              <li>Surveillance</li>
              <li>Access Control</li>
              <li>Networking</li>
              <li>Audio / Video</li>
              <li>Alarm Systems</li>
              <li>Structured Wiring</li>
          </ul>
       
        </div>

        <div className="infoContainer">
          <h2 style={{ fontSize: '1.4rem' }}> FOLLOW US ON SOCIALS </h2>
            <div className="socials">
              
              <a
                href="https://www.instagram.com/glelectronics18?igsh=aGVwbXJmbXJ5Nmjq/"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink"
              >
                <FaInstagram size={30} style={{ marginRight: "5px" }} />
              </a>

              <a
                href="https://www.facebook.com/profile.php?id=61550683222711&mibextid=uzlsIk"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink"
              >
              <BsFacebook size={30} style={{marginLeft: '15px'}}/>
              </a>

              <a
                href="https://www.linkedin.com/company/glelectronics/about/"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink"
              >
              <FaLinkedin size={31} style={{marginLeft: '15px'}}/>
              </a>

              <a
                href="https://www.yelp.com/biz/gl-electronics-mesa?osq=GL+electronics&override_cta=Get+pricing"
                target="_blank"
                rel="noopener noreferrer"
                className="socialLink"
              >
              <FaYelp size={30} style={{marginLeft: '15px'}}/>
              </a>
              
            </div>
          <span className="copyStyle">&copy; 
            Copyright 2023 - GL Electronics, LLC. 
            <br/>
            All rights reserved
          </span>
        </div>

      </footer>

      
    </div>
  );
}

export default Footer;
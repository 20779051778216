import { Link } from 'react-router-dom';
import Navigation from './Navigation';
import CallNum from './CallNum';
import React, { useState } from 'react'; // Import useState hook
import glElectronicsLogo from '../WebImg/GL-electronics-png.png';
import { IoMenu, IoClose } from "react-icons/io5"; // Import both icons
import '../Styles/HeaderStyle.css';
import MenuBar from './MenuDrop';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu visibility

  const iconSize = 40;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu state
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <header>
      <div className="header-d">
        <Link to="/" onClick={scrollToTop}>
          <img src={glElectronicsLogo} alt="GL Electronics Logo" className='logoStyle'/>
        </Link>

        <nav>
          <Navigation/>
        </nav>

        <div>
          <CallNum />
        </div>
      </div>

      {/* Header for Mobile */}
      <div className='header-m'>
        <Link to="/" onClick={scrollToTop}>
          <img src={glElectronicsLogo} alt="GL Electronics Logo" className='logoStyle'/>
        </Link>

        {isMenuOpen ? (
          <IoClose className='menu' size={iconSize} onClick={toggleMenu} style={{ cursor: 'pointer' }} />
        ) : (
          <IoMenu className='menu' size={iconSize} onClick={toggleMenu} style={{ cursor: 'pointer' }} />
        )}
      </div>
      {/* Conditionally render Navigation component based on menu state */}
      {isMenuOpen && <MenuBar closeMenu={toggleMenu} />}
    </header>
  );
}

export default Header;

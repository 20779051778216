// contact.js

import React, { useEffect } from "react";
import Form from "../Components/Form";
import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { IoIosPhonePortrait } from "react-icons/io";
import "../index.css";
import "../Styles/Contact.css";
import { InlineWidget } from "react-calendly";


function Contact() {
  useEffect(() => {window.scrollTo(0, 0); }, []);

  return (
    <div>

      <div className="App">
        <InlineWidget url="https://calendly.com/glelectronics" />
      </div>

      <div className="contentBox" >

      <div className="containerStyle">
          <div className="formHeader">
            <h1 className="headingStyle" style={{fontSize: '1.8rem'}}>Have Questions?</h1>
            <p>Send us an Email.</p>
          </div>

          <Form />
        </div>

        
        <div className="IBC">
          &emsp;
          <FaPhoneAlt size={30} style={{ marginRight: 15 }} />
          <IoIosPhonePortrait size={30} style={{ marginRight: 15 }} />
          <AiOutlineMail size={30} style={{ marginRight: 15 }} />
          <br />
          <span>
            <br />
            CALL or Text US at
            <br />
            (480)-757-5148
            <br />
            <br />
            Or Email us if you have other inquiries:
            <br />
            geoff@glelectronics.net
          </span>

          <ul style={{listStyle: 'none',}}>
            <li>Surveillance</li>
            <li>Access Control</li>
            <li>Networking</li>
            <li>Audio / Video</li>
            <li>Alarm / Video</li>
            <li>Structured Wiring</li>
          </ul>

          <div>
          <p>
                Forms of payment that we accept:
                <ul style={{listStyle: 'none',}}>
                  <li>Check</li>
                  <li>Cash</li>
                  <li>Credit/Debit</li>
                  <li>Zelle</li>
                </ul>
              </p>

          </div>
        </div>

     
      </div>

    
    </div>
  );
}

export default Contact;